import React, {useState} from 'react';
import { getSrc } from "gatsby-plugin-image"
import Layout from "components/layout";
import SeoComponent from 'components/seo';
import { useStrapiKeyLegislationPageData, useStrapiVideosWebinarsData } from "hooks";
import { Hero, Breadcrumb, Modal, VideoComponent, Button } from "components/ui";
import { Overlay } from "components/global";
import { CardContainer, FlagCardContainer } from "components/containers/Cards";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const groupBy = (array, key) => {
  return array?.reduce((result, currentValue) => {
    (result[currentValue?.category?.name] = result[currentValue?.category?.name] || []).push(
      currentValue
    );
    return result;
  }, {});
};

const KeyLegislation = ({ location }) => {
    const { strapiKeyLegislation } = useStrapiKeyLegislationPageData()
  const keyLegislationBlocks = strapiKeyLegislation?.blocks
  const keyLegislationResources = [...strapiKeyLegislation?.resources,...strapiKeyLegislation?.videos_and_webinars ]
  const groupByCategory = groupBy(keyLegislationResources, 'category')
  const categoryResources = Object.entries(groupByCategory).map(([categoryName, resourcesData]) => ({ categoryName, resourcesData }));
  const { allStrapiVideo } = useStrapiVideosWebinarsData();
  const [selectedVideoTitle, setSelectedVideoTitle] = useState("");
  const [selectedVideoId, setSelectedVideoId] = useState("");
  const [modalDisplay, setModalDisplay] = useState(false);

  const seo = {
    metaTitle: strapiKeyLegislation?.title,
    metaDescription: strapiKeyLegislation?.description,
    metaKeywords: strapiKeyLegislation?.keywords
  }

  let thisLocationPathname = ''

  if (isBrowser) {
      thisLocationPathname = window.location.pathname
  }

  const createKey = title => {
    let keyId = title.toLowerCase().replace(" ", "-");
    return keyId
  }
  const selectedVideo=e=>{
    e.preventDefault();
    setModalDisplay(!modalDisplay);
    let selectedVideo = allStrapiVideo?.nodes.filter(resource=>{
        return resource?.title === e.target.title;
    })
    setSelectedVideoTitle(selectedVideo[0].title);
    selectedVideo[0]?.video_url.includes("=") ? setSelectedVideoId(selectedVideo[0]?.video_url.substring(selectedVideo[0]?.video_url?.indexOf('=')+1)) : setSelectedVideoId(selectedVideo[0]?.video_url.substring(selectedVideo[0]?.video_url.indexOf('.')+4));
}
const updateModalDisplay=e=>{
  e.preventDefault();
  setModalDisplay(!modalDisplay);
}
const sortTopics=topics=>{
  const topicsOrder=["Policy and Practice Resources", "Related Online Training, Videos, and Webinars", "State and Local Examples", "Additional Resources"]
  let sortedTopics=topics.sort((currentTopic,topic)=>topicsOrder.indexOf(currentTopic.categoryName)-topicsOrder.indexOf(topic.categoryName))
  return sortedTopics;
}
  return (
    <Layout location={location}>
        <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords}/>
        <div>
          <Hero
            img={getSrc(strapiKeyLegislation?.hero_image?.background_image?.localFile)}
            color="#f5f5f5"
            >
              <Breadcrumb pathname={thisLocationPathname} customCurrentPage={strapiKeyLegislation?.title} />
              <h1 className="usa-hero__heading" aria-label={`Hero: ${strapiKeyLegislation?.hero_image?.headline}`} id="main-content" name="main-content">{strapiKeyLegislation?.hero_image?.headline}</h1>
              {strapiKeyLegislation?.hero_image?.description &&
                <div
                  dangerouslySetInnerHTML={{
                  __html: strapiKeyLegislation?.hero_image?.description?.data?.childMarkdownRemark?.html,
                  }}
                />
              }
            </Hero>
        </div>
        {(keyLegislationBlocks && (keyLegislationBlocks[0]?.resources?.length > 0 || keyLegislationBlocks[0]?.videos?.length > 0)) && keyLegislationBlocks?.map((block, idx) => {
          return block?.strapi_component.indexOf('highlighted')  &&
            <FlagCardContainer
              key={`highlighted-resource-card-group-${idx+1}`}
              title={block?.title}
              buttonOnClick={selectedVideo}
              resources={[...block?.resources,...block?.videos]}
              containerStyle="blue-bg"
              headerColor="text-base-lightest"
              keyName="highlighted-resource"
              cardColor="bg-white"
              grid="desktop:grid-col-12 min-height-flag-card-container card-container card-mobile"
              topicPage={false}
              />
        })}

         {categoryResources && sortTopics(categoryResources)?.map((category, idx) => {
          let bgColorIndex = idx+1;
          return (<CardContainer
            key={`key-legislation-card-group-${idx+1}`}
            title={category?.categoryName}
            buttonOnClick={selectedVideo}
            resources={category?.resourcesData}
            keyName={createKey(category?.categoryName)}
            containerStyle={bgColorIndex % 2 === 0 ? "": "light-gray-bg"}
            cardColor="bg-white"
            grid="desktop:grid-col-6"
            topicPage={false}
            />
          )
        })}

        <Overlay isVisible={modalDisplay} />
        <Modal
          modalId="key-legislation-video-webinar-modal"
          modalType="usa-modal--lg radius-lg"
          modalDisplay={modalDisplay}
        >
          <Modal.ModalButton closeModal={updateModalDisplay}/>
            <Modal.ModalBody modalBodyClass="text-center">
              <div className="padding-top-6">
                <VideoComponent
                  videoSrcId={selectedVideoId}
                  videoTitle={selectedVideoTitle}
                />
              </div>
            </Modal.ModalBody>
            <Modal.ModalFooter modalFooterClass="text-center">
              <Button color="primary-button" size="width-card-lg margin-bottom-3" closeModal={true} onClick={updateModalDisplay}>
                Close
              </Button>
            </Modal.ModalFooter>
          </Modal>
    </Layout>
  );
};
export default KeyLegislation;
